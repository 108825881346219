import React from 'react'
import PropTypes from 'prop-types'
import FlexGroup from 'Components/FlexGroup'
import InfoIcon from 'Svgs/Info'
import ErrorIcon from 'Svgs/ExclamationCircle'
import WarningIcon from 'Svgs/Exclamation'
import SuccessIcon from 'Svgs/CheckCircle'

const commonIconClasses = 'align-middle h-6 mr-4'

const AlertBlock = ({
  variant,
  className,
  style,
  children,
  fontSize,
  showIcon,
  right,
}) => {
  let variantClasses = ''
  if (variant === 'info') {
    variantClasses = 'text-blue-800 bg-blue-100 border-blue-200'
  } else if (variant === 'warning') {
    variantClasses = 'text-yellow-800 bg-yellow-100 border-yellow-200'
  } else if (variant === 'error') {
    variantClasses = 'text-red-800 bg-red-100 border-red-200'
  } else if (variant === 'success') {
    variantClasses = 'text-green-800 bg-green-100 border-green-200'
  }
  return (
    <FlexGroup
      className={`p-4 border border-solid shadow-sm rounded-lg ${variantClasses} ${className}`}
      style={style}
      left={
        <div>
          {variant === 'info' && showIcon && (
            <InfoIcon className={`${commonIconClasses} text-blue-500`} />
          )}
          {variant === 'warning' && showIcon && (
            <WarningIcon className={`${commonIconClasses} text-yellow-500`} />
          )}
          {variant === 'error' && showIcon && (
            <ErrorIcon className={`${commonIconClasses} text-red-500`} />
          )}
          {variant === 'success' && showIcon && (
            <SuccessIcon className={`${commonIconClasses} text-green-500`} />
          )}
        </div>
      }
      middle={<div className={fontSize}>{children}</div>}
      right={right}
    />
  )
}

AlertBlock.defaultProps = {
  className: '',
  style: {},
  showIcon: false,
  right: <div />,
  fontSize: 'text-lg',
}

AlertBlock.propTypes = {
  variant: PropTypes.oneOf(['warning', 'info', 'error', 'success']).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  showIcon: PropTypes.bool,
  children: PropTypes.node.isRequired,
  right: PropTypes.node,
  fontSize: PropTypes.string,
}

export default AlertBlock
