import React from 'react'
import PropTypes from 'prop-types'
import Rating from '@material-ui/lab/Rating'

const RatingBlock = ({ readOnly, rating, size, onChange, nRatings }) => (
  <div style={{ display: 'inline' }}>
    {((readOnly && rating >= 0) || !readOnly) && (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Rating
          name="courseRating"
          value={rating}
          size={size}
          readOnly={readOnly}
          onChange={(e, val) => onChange(val)}
        />
        &nbsp;
        <span style={{ fontSize: '16px' }}>
          <span className="text-gray-600">{rating}</span>
          {nRatings && (
            <span className="text-sm text-gray-600">
              &nbsp;(
              {nRatings}
              &nbsp;
              {nRatings === 1 ? 'rating' : 'ratings'})
            </span>
          )}
        </span>
      </div>
    )}
  </div>
)

RatingBlock.defaultProps = {
  onChange: () => {},
  readOnly: true,
  nRatings: null,
}

RatingBlock.propTypes = {
  size: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  rating: PropTypes.any.isRequired,
  nRatings: PropTypes.any,
}

export default RatingBlock
