const NumberWithCommas = (x) =>
  x.toString().split('.')[0].length > 3
    ? `${x
        .toString()
        .substring(0, x.toString().split('.')[0].length - 3)
        .replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${x
        .toString()
        .substring(x.toString().split('.')[0].length - 3)}`
    : x.toString()

export default NumberWithCommas
